import React, {useState, useEffect} from 'react';
import "./HomeBannerSlider.css";
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import banner1 from "../../assets/home-banner/kesri-banner-1-min.webp"
import banner2 from "../../assets/home-banner/kesri-banner-2-min.webp"
import banner3 from "../../assets/home-banner/kesri-banner-3-min.webp"
import banner1m from "../../assets/home-banner/kesri-banner-1-mobile-min.webp"
import banner2m from "../../assets/home-banner/kesri-banner-2-mobile-min.webp"
import banner3m from "../../assets/home-banner/kesri-banner-3-mobile-min.webp"



const HomeBannerSlider = () => {
    const [imagesLoaded, setImagesLoaded] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            setImagesLoaded(false);
            loadImages();
        };

        const loadImages = () => {
            const desktopImages = [
                new Image().src = banner1,
                new Image().src = banner2,
                new Image().src = banner3,
            ];

            const mobileImages = [
                new Image().src = banner1m,
                new Image().src = banner2m,
                new Image().src = banner3m,
            ];

            const imagesToLoad = window.innerWidth <= 575 ? mobileImages : desktopImages;

            Promise.all(imagesToLoad.map(src => new Promise((resolve) => {
                const img = new Image();
                img.src = src;
                img.onload = resolve; 
            })))
                .then(() => {
                    setImagesLoaded(true);
                });
        };

        loadImages();

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        fade: true,
        pauseOnHover: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        arrows: false,
    };

    return (
        // <Slider {...settings}>
        //     <div className='home-slider slider-1'>
        //         <div className="c-container">
        //             <div className='slider-text'>
        //                 <h2>Sustainable <br /> Solutions</h2>
        //                 <p>For a Brighter Tomorrow</p>
        //             </div>
        //         </div>
        //     </div>
        //     <div className='home-slider slider-2'>
        //         <div className="c-container">
        //             <div className='slider-text'>
        //                 <h2>PM-KUSUM <br /> Scheme</h2>
        //             </div>
        //         </div>
        //     </div>
        //     <div className='home-slider slider-3'>
        //         <div className="c-container">
        //             <div className='slider-text'>
        //                 <h2>Power Purchase <br /> Agreement</h2>
        //             </div>
        //         </div>
        //     </div>
        // </Slider>
        <Slider {...settings}>
            <div className={`home-slider slider-1 slider ${imagesLoaded ? 'slider-loaded' : ''} ${window.innerWidth <= 575 ? 'slider-loaded-mobile' : ''}`}>
                <div className="c-container">
                    <div className='slider-text'>
                        <h2>Sustainable <br /> Solutions</h2>
                        <p>For a Brighter Tomorrow</p>
                    </div>
                </div>
            </div>
            <div className={`home-slider slider-2 slider ${imagesLoaded ? 'slider-loaded' : ''} ${window.innerWidth <= 575 ? 'slider-loaded-mobile' : ''}`}>
                <div className="c-container">
                    <div className='slider-text'>
                        <h2>PM-KUSUM <br /> Scheme</h2>
                    </div>
                </div>
            </div>
            <div className={`home-slider slider-3 slider ${imagesLoaded ? 'slider-loaded' : ''} ${window.innerWidth <= 575 ? 'slider-loaded-mobile' : ''}`}>
                <div className="c-container">
                    <div className='slider-text'>
                        <h2>Power Purchase <br /> Agreement</h2>
                    </div>
                </div>
            </div>
        </Slider>
    );
};

export default HomeBannerSlider;
