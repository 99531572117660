import React, { useRef }  from 'react';
import Breadcrumb from '../../components/BreadCrumb/BreadCrumb';
import { Helmet } from 'react-helmet-async';
import './Disclaimer.css';
import pdf from "../../assets/Disclaimer for Kesrinandan Ventures LLP.pdf";
import { usePdf } from '@mikecousins/react-pdf';
import BigTitle2 from '../../components/BigTitle/BigTitle2';

const Disclaimer = () => {
    const canvasRef = useRef(null);

    const { pdfDocument } = usePdf({
        file: pdf,
        canvasRef,
    });


    return (
        <>
            <Helmet>
                <title>Disclaimer | Kesrinandan Ventures LLP</title>
                <meta
                    name="description"
                    content="Read the disclaimer for Kesrinandan Ventures, outlining the limitations of liability and terms of use for our products and services. Stay informed about our policies and practices."
                />
                <link rel="canonical" href="https://kesrinandan.com/disclaimer" />
                <meta name="robots" content="noindex"/>
            </Helmet>

            <section className="about-banner career-banner">
                <div className="custom-text custom-text-about">
                    <div className="breadcrumb-hide" style={{ visibility: "hidden" }}>
                        <Breadcrumb paths={[{ label: 'Home', url: '/' }, { label: 'Career', url: '/career' }]} />
                    </div>
                </div>
            </section>

            <section className="banner-heading-section banner-heading-section2">
                <div className="c-container">
                    <BigTitle2 heading="Disclaimer" />
                </div>
            </section>

            <div className="disclaimer-section">
                <div className="c-container">
                    <div className='canvas-container'>
                        {!pdfDocument && <span>Loading...</span>}
                        <canvas ref={canvasRef} />
                    </div>
                </div>
            </div>

        </>

    )
}

export default Disclaimer