import React, { useState, useEffect, Suspense, useContext } from 'react'
import "../BrandDetail/BrandDetail.css"

import { Helmet } from 'react-helmet-async';
import * as Accordion from '@radix-ui/react-accordion';
import classNames from 'classnames';
import { FaPlus, FaMinus } from "react-icons/fa";
import { useNavigate } from 'react-router-dom';
import { AppContext } from '../../AppContext';
import partnerSvg from "../../assets/become-partner-vector.svg"
import { useForm, Controller } from 'react-hook-form';

import { RiMapPin4Line } from "react-icons/ri";
import { LuUser2 } from "react-icons/lu";
import { FiPhone } from "react-icons/fi";
import { LiaCitySolid } from "react-icons/lia";
import { VscTypeHierarchySub } from "react-icons/vsc";
import { Link } from 'react-router-dom';


const ReCAPTCHA = React.lazy(() => import('react-google-recaptcha'));


const Axitec = () => {
    const [showReCAPTCHA, setShowReCAPTCHA] = useState(false);
    const [captchaError, setCaptchaError] = useState(null);
    const [captchaToken, setCaptchaToken] = useState(null);
    const { states, city, setSelectedState } = useContext(AppContext);
    const Navigate = useNavigate()
    const { control, register, handleSubmit, reset, formState: { errors }, watch, setValue } = useForm();
    const selectedState = watch('state')

    const handleFormInteraction = () => {
        if (!showReCAPTCHA) {
            setShowReCAPTCHA(true);
        }
    };

    useEffect(() => {
        setSelectedState(selectedState);
    }, [selectedState, setSelectedState]);

    const handleVerificationSuccess = (token) => {
        setCaptchaToken(token);
    };

    const onSubmit = async (data) => {
        data.captchaToken = captchaToken;
        const formBody = new URLSearchParams(data).toString();
        try {
            const response = await fetch('https://kesrinandan.com/admin/api/landingpageform', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: formBody,
            });

            const responseData = await response.json();

            if (response.ok) {
                reset()
                Navigate("/thank-you")
            } else {
                if (responseData.msg && responseData.msg === "Invalid CAPTCHA. Please try again") {
                    setCaptchaError(responseData.msg);
                } else {
                    console.error('Failed to submit the form:', responseData.error);
                }
            }
        } catch (error) {
            console.error('Error submitting the form:', error);
        }
    };

    const AccordionTrigger = React.forwardRef(({ children, className, ...props }, forwardedRef) => (
        <Accordion.Header className="AccordionHeader">
            <Accordion.Trigger
                className={classNames('AccordionTrigger', className)}
                {...props}
                ref={forwardedRef}
            >
                {children}
                <FaPlus className={` toggle-on`} />
                <FaMinus className={`toggle-off`} />
            </Accordion.Trigger>
        </Accordion.Header>
    ));



    const AccordionContent = React.forwardRef(({ children, className, ...props }, forwardedRef) => (
        <Accordion.Content
            className={classNames('AccordionContent', className)}
            {...props}
            ref={forwardedRef}
        >
            <div className="AccordionContentText">{children}</div>
        </Accordion.Content>
    ));


    return (
        <>
            <Helmet>
                <meta name="robots" content="noindex, nofollow" />
                <title>Landing Page</title>
            </Helmet>

            <section className="about-banner brands-detail-banner">
                <div className="custom-text custom-text-about">
                    <p className='custom-title' style={{ textTransform: "capitalize" }}>
                        Axitec<span className='color-dot'>.</span>
                    </p>
                </div>
            </section>

            <section className="brand-detail-section">
                <div>
                    <div className="brand-logo">
                        <h2>Axitec Solar Panels</h2>
                    </div>
                    <div className="brands-desc">
                        <div>
                            The solar modules are ideal for installations where a sleek black exterior must match a high power output. The half-cell technology power modules have lower cell resistance and thus excellent performance compared to full-cell solar modules. AXITEC guarantees that its glass-glass photovoltaic modules (excluding those with HJT cells) are free from material and workmanship defects for 30 years, as specified in the warranty terms.
                        </div>
                    </div>
                </div>
                <div class="brand-products">
                    <div class="products-item">
                        <Link to={"/product-detail/axibiperfect-gxq-ts"}>
                            <div class="products-item-img">
                                <img src="https://kesrinandan.com/admin/uploads/product/2024-05-13-120235_2023.png" alt="" />
                            </div>
                            <h5>Axitec Bi Perfect GXQ TS</h5>
                        </Link>
                    </div>
                    <div class="products-item">
                        <Link to={"/product-detail/axibiperfect-gq-ts"}>
                            <div class="products-item-img">
                                <img src="https://kesrinandan.com/admin/uploads/product/2024-05-13-115656_2023.png" alt="" />
                            </div>
                            <h5>Axitec Bi Perfect GQ TS</h5>
                        </Link>
                    </div>
                    <div class="products-item">
                        <Link to={"/product-detail/axibiperfect-gl-ts"}>
                            <div class="products-item-img">
                                <img src="https://kesrinandan.com/admin/uploads/product/2024-05-13-115124_2023.png" alt="" />
                            </div>
                            <h5>Axitec Bi Perfect GL TS</h5>
                        </Link>
                    </div>
                    <div class="products-item">
                        <Link to={"/product-detail/axibiperfect-gxxl-ts"}>
                            <div class="products-item-img">
                                <img src="https://kesrinandan.com/admin/uploads/product/2024-05-13-114554_2023.png" alt="" />
                            </div>
                            <h5>Axitec Bi Perfect GXXL TS</h5>
                        </Link>
                    </div>
                    <div class="products-item">
                        <Link to={"/product-detail/axibiperfect-gl-wb"}>
                            <div class="products-item-img">
                                <img src="https://kesrinandan.com/admin/uploads/product/2024-05-13-113727_2023.png" alt="" />
                            </div>
                            <h5>Axitec Bi Perfect GL WB</h5>
                        </Link>
                    </div>
                    <div class="products-item">
                        <Link to={"/product-detail/axiblackbiperfect-gl"}>
                            <div class="products-item-img">
                                <img src="https://kesrinandan.com/admin/uploads/product/2024-05-13-112350_2023.png" alt="" />
                            </div>
                            <h5>Axitec Black Bi Perfect GL</h5>
                        </Link>
                    </div>
                    <div class="products-item">
                        <Link to={"/product-detail/axibiperfect-fxxl-ts"}>
                            <div class="products-item-img">
                                <img src="https://kesrinandan.com/admin/uploads/product/2024-02-03-101030_2023.png" alt="" />
                            </div>
                            <h5>Axitec Bi Perfect FXXL TS</h5>
                        </Link>
                    </div>
                    <div class="products-item">
                        <Link to={"/product-detail/axibipremium-xxl-hc-mt"}>
                            <div class="products-item-img">
                                <img src="https://kesrinandan.com/admin/uploads/product/2024-02-03-100933_2023.png" alt="" />
                            </div>
                            <h5>Axitec Bi Premium XXL HC MT</h5>
                        </Link>
                    </div>
                    <div class="products-item">
                        <Link to={"0/product-detail/axiworldpremium-xxl-hc"}>
                            <div class="products-item-img">
                                <img src="https://kesrinandan.com/admin/uploads/product/2024-02-03-100821_2023.png" alt="" />
                            </div>
                            <h5>Axitec World Premium XXL HC</h5>
                        </Link>
                    </div>
                    <div class="products-item">
                        <Link to={"/product-detail/axipremium-x"}>
                            <div class="products-item-img">
                                <img src="https://kesrinandan.com/admin/uploads/product/2024-02-03-100614_2023.png" alt="" />
                            </div>
                            <h5>Axitec Premium X</h5>
                        </Link>
                    </div>
                    <div class="products-item">
                        <Link to={"/product-detail/axiworldpower"}>
                            <div class="products-item-img">
                                <img src="https://kesrinandan.com/admin/uploads/product/2024-02-03-100457_2023.png" alt="" />
                            </div>
                            <h5>Axitec Worldpower</h5>
                        </Link>
                    </div>
                    <div class="products-item">
                        <Link to={"/product-detail/axipremium-xl-hc-525-545-wp"}>
                            <div class="products-item-img">
                                <img src="https://kesrinandan.com/admin/uploads/product/2024-02-03-100220_2023.png" alt="" />
                            </div>
                            <h5>Axitec Premium XL HC (525-545WP)</h5>
                        </Link>
                    </div>
                    <div class="products-item">
                        <Link to={"/product-detail/axipremium-xl-hc-430-455-wp"}>
                            <div class="products-item-img">
                                <img src="https://kesrinandan.com/admin/uploads/product/2024-02-03-100341_2023.png" alt="" />
                            </div>
                            <h5>Axitec Premium XL HC (430-455WP)</h5>
                        </Link>

                    </div>
                </div>
                <div className="brands-desc brand-desc-2">
                    <div><h1>Why Choose Axitec Solar Panels?</h1>

                        <ul>
                            <li>
                                <p><strong>1.Proven Performance:</strong> Axitec sees its past as a foundation for its activity in producing the most efficient solar modules. Their panels cannot deviate from the agreement and are designed to work for years and years.</p>
                            </li>
                            <li>
                                <p><strong>2.Variety of Options: </strong>Kesrinandan, a leading <a href="https://kesrinandan.com/">solar system distributor</a>&nbsp;is within reach of Axitec branded solar disks, which are available in monocrystalline or polycrystalline form and offer various wattages, depending on your power needs.</p>
                            </li>
                            <li>
                                <p><strong>3.Durability:</strong> <a href="https://www.axitecsolar.com/en/qualitaet-0">AXITEC guarantees</a> that AXIperfect modules will perform at 99% of their original power after the initial year. Thereafter, the power will only decrease by a maximum of 0.4% per year. Indicates that even after 30 years, you will get at least 87.5% of the original power from your solar panels.</p>
                            </li>
                            <li>
                                <p><strong>4.Global Quality System:</strong> High-quality results in long-lasting performance. With the global quality system, Axitec assures customers more power, yearly. As a customer, you can rest assured that the supply of your photovoltaic system is secured for 30 years.</p>
                            </li>
                        </ul>

                        <h2>Invest In A Sustainable Future With Axitec Solar Panels</h2>

                        <p>Discover our Axitec solar panels, which offer you total energy freedom by browsing our massive range of options. Contact us immediately for professional suggestions that can be availed online by discreetly ordering.</p>
                    </div>
                </div>
            </section>


            <div className="landing-page-section-bg">
                <div className="c-container">
                    <section className="become-partner">
                        <div className="partner-content">
                            <div className="partner-info">
                                <h2>Become a Dealer</h2>
                                <p>Join Kesrinandan Ventures today and start your own business with our solar panel dealership program. This is the best opportunity to expand your business in the rapidly growing solar industry in various cities. Sign up today and profit from the highly demanding solar energy solutions.</p>
                            </div>
                            <img src={partnerSvg} alt="Descriptive Alt Text" className="partner-image" />
                        </div>
                        <div className="partner-form">
                            <form onFocus={handleFormInteraction} onClick={handleFormInteraction} onSubmit={handleSubmit(onSubmit)} className="vertical-form">
                                <div className="form-container form-container2">
                                    <div className="form-group">
                                        <div className="icon">
                                            <LuUser2 />
                                        </div>
                                        <input type="text" className='form-control' placeholder='Your name' {...register('name', { required: 'Required' })} />
                                        {errors.name && <div className="error-message">{errors.name.message}</div>}
                                    </div>

                                    <div className="form-group">
                                        <div className="icon">
                                            <FiPhone />
                                        </div>
                                        <input type="tel" className='form-control' placeholder='Phone number' {...register('phone', {
                                            required: 'Required', pattern: {
                                                value: /^\+?\d{1,3}(\s?\d{6,13})?$/,
                                                message: 'Invalid phone number',
                                            }
                                        })} />
                                        {errors.phone && <div className="error-message">{errors.phone.message}</div>}
                                    </div>

                                    <div className="form-group">
                                        <div className="icon">
                                            <VscTypeHierarchySub />
                                        </div>
                                        <Controller
                                            name="product"
                                            defaultValue={""}
                                            control={control}
                                            rules={{ required: 'Please select Product' }}
                                            render={({ field }) => (
                                                <select {...field} className='form-control'>
                                                    <option value="" disabled>Select Product</option>
                                                    <option value="Solar Panels">Solar Panels</option>
                                                    <option value="Inverters">Inverters</option>
                                                    <option value="Cables Wires">Cables Wires</option>
                                                </select>
                                            )}
                                        />
                                        {errors.product && <div className="error-message">{errors.product.message}</div>}
                                    </div>

                                    <div className="form-group">
                                        <div className="icon">
                                            <RiMapPin4Line />
                                        </div>
                                        <Controller
                                            name="state"
                                            defaultValue={""}
                                            control={control}
                                            rules={{ required: 'Please select a state' }}
                                            render={({ field }) => (
                                                <select {...field} className='form-control' onChange={(e) => setValue('state', e.target.value)}>
                                                    <option value="">Select a state</option>
                                                    {
                                                        states
                                                            .map((el) => (
                                                                <option key={el.id} value={el.id}>
                                                                    {el.state}
                                                                </option>
                                                            ))}
                                                </select>
                                            )}
                                        />
                                        {errors.state && <div className="error-message">{errors.state.message}</div>}
                                    </div>

                                    <div className="form-group">
                                        <div className="icon">
                                            <LiaCitySolid />
                                        </div>
                                        <Controller
                                            name="city"
                                            defaultValue={""}
                                            control={control}
                                            rules={{
                                                required: 'Please select a city'
                                            }}

                                            render={({ field }) => (
                                                <select {...field} className='form-control' onChange={(e) => setValue('city', e.target.value)}>
                                                    <option value="" disabled>Select a city</option>
                                                    {city?.map((el) => {
                                                        return (
                                                            <option key={el?.id} value={el?.id}>
                                                                {el?.city_name}
                                                            </option>
                                                        )
                                                    })}
                                                </select>
                                            )}
                                        />
                                        {errors.city && <div className="error-message">{errors.city.message}</div>}
                                    </div>

                                    <div className="form-group">
                                        <div className="icon">
                                            <VscTypeHierarchySub />
                                        </div>
                                        <Controller
                                            name="godown_available"
                                            defaultValue={""}
                                            control={control}
                                            rules={{ required: 'Please Select' }}
                                            render={({ field }) => (
                                                <select {...field} className='form-control'>
                                                    <option value="" disabled>Is Godwon Area Available?</option>
                                                    <option value="Y">Yes</option>
                                                    <option value="N">No</option>
                                                </select>
                                            )}
                                        />
                                        {errors.godown_available && <div className="error-message">{errors.godown_available.message}</div>}
                                    </div>

                                    <div className="form-group w-100">
                                        <div className="icon">
                                            <VscTypeHierarchySub />
                                        </div>
                                        <Controller
                                            name="interested"
                                            defaultValue={""}
                                            control={control}
                                            rules={{ required: 'Please Select' }}
                                            render={({ field }) => (
                                                <select {...field} className='form-control'>
                                                    <option value="" disabled>Need to submit 10 Lac as a security amount. Interested?</option>
                                                    <option value="Y">Yes</option>
                                                    <option value="N">No</option>
                                                </select>
                                            )}
                                        />
                                        {errors.interested && <div className="error-message">{errors.interested.message}</div>}
                                    </div>

                                    <div className="form-group w-100">
                                        <div className="icon">
                                            <VscTypeHierarchySub />
                                        </div>
                                        <Controller
                                            name="capital_invest"
                                            defaultValue={""}
                                            control={control}
                                            rules={{ required: 'Please Select' }}
                                            render={({ field }) => (
                                                <select {...field} className='form-control'>
                                                    <option value="" disabled>How much capital you can invest?(Lac)</option>
                                                    <option value="5">5</option>
                                                    <option value="6">6</option>
                                                    <option value="7">7</option>
                                                    <option value="8">8</option>
                                                    <option value="9">9</option>
                                                    <option value="10">10</option>
                                                </select>
                                            )}
                                        />
                                        {errors.capital_invest && <div className="error-message">{errors.capital_invest.message}</div>}
                                    </div>


                                    <div className="form-group text-area-group w-100" >
                                        <textarea placeholder='Your message' className='form-control form-control-textarea' {...register('message', { required: 'Required' })} />
                                        {errors.message && <div className="error-message">{errors.message.message}</div>}
                                    </div>

                                    <div className="form-group w-100">
                                        {showReCAPTCHA && (
                                            <Suspense fallback={<div>Loading reCAPTCHA...</div>}>
                                                <ReCAPTCHA
                                                    sitekey="6Lc5zpQpAAAAAO9suPoYtJaeB5r57wNwKbkCbSjX"
                                                    onChange={handleVerificationSuccess}
                                                />
                                            </Suspense>
                                        )}
                                        {captchaError && <div className="error-message">{captchaError}</div>}
                                    </div>

                                </div>
                                <div className="form-footer">
                                    <button className='common-btn enquiry-btn' type="submit">Submit Request</button>
                                </div>
                            </form>
                        </div>
                    </section>
                </div>
            </div>


            <section className="faq-section">
                <div className="c-container">
                    <h2 className="description">If you have any questions, we will be very happy to answer them</h2>
                    <div className="faq-accordion-section">
                        <Accordion.Root className="AccordionRoot" type="single" collapsible>
                            <Accordion.Item className="AccordionItem" value={1}>
                                <AccordionTrigger>What size of the Axitec photovoltaic panel system do I need?</AccordionTrigger>
                                <AccordionContent>Your energy needs determine the size of the Axitec solar panel system that you will install. Our team can help you discover the best system size for you.
                                </AccordionContent>
                            </Accordion.Item>
                            <Accordion.Item className="AccordionItem" value={2}>
                                <AccordionTrigger>Do I require a permit to put in the Axitec solar panels?</AccordionTrigger>
                                <AccordionContent>Local variations apply to permitting. Before starting renovation works, please confirm with your local building department and establish whether permit issuance is necessary.
                                </AccordionContent>
                            </Accordion.Item>
                            <Accordion.Item className="AccordionItem" value={3}>
                                <AccordionTrigger>Where are Axitec Solar Panels made?</AccordionTrigger>
                                <AccordionContent>Axitec solar panels are made in Asia and Europe. The brand, established in 2001, is a German-based manufacturer of solar panels and energy storage solutions.
                                </AccordionContent>
                            </Accordion.Item>
                        </Accordion.Root>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Axitec