import React, { useState, useEffect } from 'react';
import "./OurBrands.css"
import { ColorRing } from 'react-loader-spinner';
import { Helmet } from 'react-helmet-async';
import Breadcrumb from '../../components/BreadCrumb/BreadCrumb';
import { Link } from 'react-router-dom';


const OurBrands = () => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch('https://kesrinandan.com/admin/api/partner/all')
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }

                const result = await response.json();
                setData(result.data);
            } catch (error) {
                setError(error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);


    return (
        <>

            <Helmet>
                <title>Our Partners - Collaborate for Success | Kesrinandan Ventures</title>
                <meta
                    name="description"
                    content="Discover the collaborative potential with Kesrinandan's partner program. Join us to unlock growth opportunities, innovative solutions, and mutual success."
                />
                <link rel="canonical" href="https://kesrinandan.com/partners" />

                {/* Facebook Meta Tags */}
                <meta property="og:url" content="https://kesrinandan.com/partners" />
                <meta property="og:type" content="website" />
                <meta property="og:title" content="Our Partners - Collaborate for Success | Kesrinandan Ventures" />
                <meta property="og:description" content="Discover the collaborative potential with Kesrinandan's partner program. Join us to unlock growth opportunities, innovative solutions, and mutual success." />
                <meta property="og:image" content="https://kesrinandan.com/static/media/brand-banner.daf12ba7db4663c9e2ee.png" />

                {/* Twitter Meta Tags */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta property="twitter:domain" content="kesrinandan.com" />
                <meta property="twitter:url" content="https://kesrinandan.com/partners" />
                <meta name="twitter:title" content="Our Partners - Collaborate for Success | Kesrinandan Ventures" />
                <meta name="twitter:description" content="Discover the collaborative potential with Kesrinandan's partner program. Join us to unlock growth opportunities, innovative solutions, and mutual success." />
                <meta name="twitter:image" content="https://kesrinandan.com/static/media/brand-banner.daf12ba7db4663c9e2ee.png" />

            </Helmet>

            <section className="about-banner brands-banner">
                <div className="custom-text custom-text-about">
                    <h1 className='custom-title'>Our Partners<span className='color-dot'>.</span></h1>
                    <Breadcrumb paths={[{ label: 'Home', url: '/' }, { label: 'Partners', url: '/partners' }]} />
                </div>
            </section>

            {loading ? <ColorRing
                visible={true}
                height="80"
                width="80"
                ariaLabel="color-ring-loading"
                wrapperStyle={{}}
                wrapperClass="color-ring-wrapper"
                colors={['#FFCA4D', '#FFCA4D', '#FFCA4D', '#FFCA4D', '#FFCA4D',]}
            /> : (

                data?.map((data) => {
                    return (


                        <section className="brand-detail-section" key={data.id}>
                            <div className="c-container">

                                {loading ? <ColorRing
                                    visible={true}
                                    height="80"
                                    width="80"
                                    ariaLabel="color-ring-loading"
                                    wrapperStyle={{}}
                                    wrapperClass="color-ring-wrapper"
                                    colors={['#FFCA4D', '#FFCA4D', '#FFCA4D', '#FFCA4D', '#FFCA4D',]}
                                /> : (
                                    <>
                                        <div>
                                            <div className="brand-logo">
                                                <img src={data?.image} alt="" />
                                            </div>
                                            <h2 className='brand-title'>{data?.short_description}</h2>
                                            <div className="brands-desc">
                                                < div dangerouslySetInnerHTML={{ __html: `${data?.description}` }} />
                                            </div>
                                        </div>
                                        <div className='brand-products'>{
                                            data?.products_data?.slice(0, 3).map((el, index) => {
                                                return (
                                                    <div className="products-item" key={index} >
                                                        <Link to={`/product-detail/${el?.slug}`}>
                                                            <div className="products-item-img">
                                                                <img src={el?.image} alt="" />
                                                            </div>
                                                            <h5>{el?.title}</h5>
                                                        </Link>
                                                    </div>
                                                )
                                            })
                                        }</div>

                                        <Link className="common-btn p-btn" style={{ marginTop: "1.8rem" }} to={`/brand-detail/${data?.slug}`}>Find More</Link>
                                    </>
                                )}
                            </div>
                        </section>
                    )
                })
            )}
        </>
    );
};

export default OurBrands;
