import React, { useState, useEffect } from 'react';
import "./About.css";
import "../../components/AboutSection/AboutSection.css"
import WhyChooseUs from '../../components/WhyChooseUs/WhyChooseUs';
import Testimonials from '../../components/Testimonials/Testimonials';
import { FaLocationDot } from "react-icons/fa6";
import { FaArrowRightLong } from "react-icons/fa6";
import { Helmet } from 'react-helmet-async';
import { ColorRing } from 'react-loader-spinner';
import BigTitle from '../../components/BigTitle/BigTitle';
import { HashLink } from 'react-router-hash-link';
import { Link } from 'react-router-dom';


import img1 from "../../assets/about/Untitled-3 1.webp";
import img2 from "../../assets/about/Untitled-4 1.webp";
import map from "../../assets/home/map-india (4).png";
import man from "../../assets/about/rohit pareta.webp";

import img3 from "../../assets/infra/specialist-technician-professional-engineer-with-laptop-tablet-maintenance-checking-installing-solar-roof-panel-factory-rooftop-sunlight-engineers-holding-tablet-check-solar-roof 1.webp";
import icon from "../../assets/infra/tick.svg";
import logo1 from "../../assets/infra/1.svg";
import logo2 from "../../assets/infra/2.svg";
import logo3 from "../../assets/infra/3.svg";
import logo4 from "../../assets/infra/4.svg";
import logo5 from "../../assets/infra/5.svg";
import cert from "../../assets/certificates/KesrinandanVenturesLLP_page-0001-min.webp";
import cert1 from "../../assets/certificates/cert 1.png";
import Breadcrumb from '../../components/BreadCrumb/BreadCrumb';

import Icon2 from "../../assets/home/Group-1.svg";


const About = () => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const scrollWithOffset = (el, offset) => {
        const elementPosition = el.getBoundingClientRect().top + window.pageYOffset - offset;
        window.scroll({
            top: elementPosition,
        });
    };


    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch('https://kesrinandan.com/admin/api/gallery/all')
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }

                const result = await response.json();
                setData(result.data);
            } catch (error) {
                setError(error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);
    return (
        <>
            <Helmet>
                <title>Leading Solar Panel Dealers in India | Kesrinandan Ventures</title>
                <meta
                    name="description"
                    content="Kesrinandan Ventures is India’s leading solar panel dealer offering sustainable energy solutions to make solar panels accessible and affordable for everyone. Contact us!"
                />
                {/* Facebook Meta Tags */}
                <meta property="og:url" content="https://kesrinandan.com/our-company" />
                <meta property="og:type" content="website" />
                <meta property="og:title" content="Leading Solar Panel Dealers in India | Kesrinandan Ventures" />
                <meta property="og:description" content="Kesrinandan Ventures is India’s leading solar panel dealer offering sustainable energy solutions to make solar panels accessible and affordable for everyone. Contact us!" />
                <meta property="og:image" content="https://kesrinandan.com/static/media/image%201.d91231295d93476dd0a8.webp" />

                {/* Twitter Meta Tags */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta property="twitter:domain" content="kesrinandan.com" />
                <meta property="twitter:url" content="https://kesrinandan.com/our-company" />
                <meta name="twitter:title" content="Leading Solar Panel Dealers in India | Kesrinandan Ventures" />
                <meta name="twitter:description" content="Kesrinandan Ventures is India’s leading solar panel dealer offering sustainable energy solutions to make solar panels accessible and affordable for everyone. Contact us!" />
                <meta name="twitter:image" content="https://kesrinandan.com/static/media/image%201.d91231295d93476dd0a8.webp" />

                <link rel="canonical" href="https://kesrinandan.com/our-company" />

                <script type="application/ld+json">
                    {`
                    {
                      "@context": "http://schema.org",
                      "@type": "Person",
                      "name": "Rohit Pareta",
                      "jobTitle": "Solar panel distributor",
                      "gender": "Male",
                      "url": "https://kesrinandan.com/our-company",
                      "description": "Rohit Pareta, a visionary leader and an expert in the field of renewable energy with over 12 years of experience.",
                      "honorificPrefix": "Master of Business Administration, Secretary and Treasurer, BNI Tathastu",
                      "nationality": "Indian",
                      "sameAs": "https://in.linkedin.com/in/rohit-pareta-44338b22",
                      "image": "https://kesrinandan.com/static/media/rohit%20pareta.02cde2c519a77a5b41a9.png",
                      "worksFor": {
                        "@type": "Organization",
                        "name": "Kesrinandan",
                        "url": "https://kesrinandan.com/",
                        "address": {
                          "@type": "PostalAddress",
                          "addressCountry": "IN",
                          "postalCode": "324005",
                          "addressLocality": "Kota",
                          "streetAddress": "Shop No. 33-34, Near Hanuman Temple, Talwandi, Kota, Rajasthan 324005"
                        }
                      }
                    }
                    `}
                </script>

            </Helmet>
            <section className="about-banner">
                <div className="custom-text custom-text-about">
                    <p className='custom-title'>About Us<span className='color-dot'>.</span></p>
                    <Breadcrumb paths={[{ label: 'Home', url: '/' }, { label: 'About', url: '/our-company' }]} />
                </div>
            </section>


            <div className="c-container">
                <section className="about-section">
                    <div className="about-section-bottom">
                        <div className="about-section-bottom-img">
                            <img src={img1} alt="Sustainable Energy Solutions" loading='lazy' />
                        </div>
                        <div className="about-section-bottom-content">
                            <h1 className='about-section-bottom-heading'>Sustainable Energy Solutions</h1>
                            <p className='about-section-bottom-desc  about-section-bottom-desc2'>Kesrinandan Ventures LLP business, established in Rajasthan, has been distributing solar products for project development, <Link to={"/product/solar-panels"} target='_blank'> rooftop solar solutions</Link>, and solar water pumps since 2011. We are well known for offering premium, cost-effective sustainable energy solutions that leverage cutting-edge technology. We work with many different commercial, governmental, and industrial sectors in India. We began serving consumers in Kota, among other cities in Rajasthan, and have since expanded throughout all of India. Our official distributors for well-known manufacturers including Leoni, Axitec, Panasonic, REC, and Livguard.</p>

                            <p className='about-section-bottom-desc about-section-bottom-desc2'>Our main goal is to use sunshine to power our world and make it better. Our objective is to build more sustainable and ecologically friendly communities. Using solar energy benefits everyone by reducing electricity use and creating a cleaner, greener planet.</p>
                            <Link className="common-btn about-btn" to={"/contact"}>
                                Get In Touch
                            </Link>
                        </div>
                    </div>

                    <div className="about-section-bottom about-section-bottom2 about-section-bottom-padding ">
                        <div className="about-section-bottom-content">
                            <h2 className='about-section-bottom-heading'>Solar Panel Distributors & Investors</h2>
                            <p className='about-section-bottom-desc about-section-bottom-desc2'>A large-scale distributor of premium solar products from Rajasthan, such as inverters, wire & cables, and solar panels, is Kesrinandan Ventures LLP. Our role as authorized channel partners for well-known brands like Axitec, Panasonic, REC, Livguard, and others is crucial in determining the direction of the renewable energy industry. We have the ability to assist top Indian distributors of solar products with sales and service assistance.</p>

                            <p className='about-section-bottom-desc about-section-bottom-desc2'>In addition, we guarantee flawless logistics and effective operations with our cutting-edge clearance, freight, and custom-bound warehouse facilities, guaranteeing on-time delivery and first-rate service to our valued clients. One creative solution at a time, Kesrinandan Ventures LLP is more than simply a company—we are leaders in spearheading the shift towards a sustainable energy future.</p>
                            <Link className="common-btn about-btn" to={"/contact"}>
                                Get In Touch
                            </Link>
                        </div>
                        <div className="about-section-bottom-img about-section-bottom-img2">
                            <img src={img2} alt="Solar Panel Distributors & Investors" loading='lazy' />
                        </div>
                    </div>
                </section>
            </div>



            <section className="location-content">
                <div className="c-container">
                    <div className="about-map-section">
                        <div className="loction-container loction-container2">
                            <div className="area about-area">
                                <p><FaLocationDot color='#FFB300' /> < HashLink className='location-a' to={"/location#rajasthan"} scroll={el => scrollWithOffset(el, 80)}>Rajasthan</HashLink></p>
                                <p><FaLocationDot color='#FFB300' /> <HashLink className='location-a' to={"/location#delhi"} scroll={el => scrollWithOffset(el, 80)}>Delhi</HashLink></p>
                                <p><FaLocationDot color='#FFB300' /> <HashLink className='location-a' to={"/location#maharashtra"} scroll={el => scrollWithOffset(el, 80)}> Maharashtra</HashLink></p>
                                <p><FaLocationDot color='#FFB300' /> <HashLink className='location-a' to={"/location#madhya-pradesh"} scroll={el => scrollWithOffset(el, 80)}>Madhya Pradesh</HashLink></p>
                                <p><FaLocationDot color='#FFB300' /> <HashLink className='location-a' to={"/location#uttar-pradesh"} scroll={el => scrollWithOffset(el, 80)}>Uttar Pradesh</HashLink></p>
                            </div>
                            <div className="map">
                                <img src={map} alt="Our Locations" loading='lazy' />
                                <FaLocationDot className='map-1' />
                                <FaLocationDot className='map-2' />
                                <FaLocationDot className='map-3' />
                                <FaLocationDot className='map-4' />
                                <FaLocationDot className='map-5' />
                            </div>
                        </div>

                        <div className="director-info">
                            <div className="director-desc">
                                <div className="director-img">
                                    <img src={man} alt="Rohit Pareta- Director of Kesrinandan" loading='lazy' />
                                </div>
                                <div className="director-title">
                                    <p className='director-name'>Rohit Pareta</p>
                                    <p>Director</p>
                                    <Link to={"https://www.linkedin.com/in/rohit-pareta-44338b22/"} target='_blank'>
                                        <img src={Icon2} alt="linkedin" className="footer-icon" />
                                    </Link>
                                </div>
                            </div>
                            <div className="director-detail">
                                <p className='director-detail-small-desc'>Rohit Pareta, a visionary leader and an expert in the field of renewable energy with over 12 years of expreience. With his remarkable leadership qualities and a profound understanding of the renewable energy landscape, Mr. Pareta took kesrinandan Ventures LLP to a new heights of success.</p>

                                <div className="director-detail-points">
                                    <p className='director-detail-point'>
                                        <FaArrowRightLong className='point-right-arrow' /> 12 Years of Bussiness Expreience.
                                    </p>
                                    <p className='director-detail-point'>
                                        <FaArrowRightLong className='point-right-arrow' /> Born in Kota Rajasthan.
                                    </p>
                                    <p className='director-detail-point'>
                                        <FaArrowRightLong className='point-right-arrow' /> Master of Bussiness Administration.
                                    </p>
                                    <p className='director-detail-point'>
                                        <FaArrowRightLong className='point-right-arrow' /> Secretary and Treasurer, BNI Tathastu.
                                    </p>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>



            <div className="c-container">
                <section className="about-section">
                    <div className="about-section-bottom about-section-bottom2">
                        <div className="about-section-bottom-content">
                            <h3 className='about-section-bottom-heading'>Smart Infrastructure for swift Operations</h3>
                            <p className='about-section-bottom-desc about-section-bottom-desc2'>Situated in Rajasthan, we are a leading solar system dealer and guarantee quality in all aspects of your solar projects. Our 4500-square-foot warehouse is furnished with cutting-edge technology storage equipment, a smart warehouse system, and a group of highly qualified employees committed to providing top-notch items.</p>

                            <div className="machinary-facilitity">
                                <p className='m-f-t'>List of Machineries & Facility:</p>
                                <div className="machine-items">
                                    <div>
                                        <img src={icon} alt="icon" loading='lazy' />
                                        Conveyors
                                    </div>
                                    <div>
                                        <img src={icon} alt="icon" loading='lazy' />
                                        Forklifts
                                    </div>
                                    <div>
                                        <img src={icon} alt="icon" loading='lazy' />
                                        Hand trucks
                                    </div>
                                    <div>
                                        <img src={icon} alt="icon" loading='lazy' />
                                        Packaging supplies
                                    </div>
                                    <div>
                                        <img src={icon} alt="icon" loading='lazy' />
                                        Storage systems
                                    </div>
                                    <div>
                                        <img src={icon} alt="icon" loading='lazy' />
                                        Pallet racks
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="about-section-bottom-img about-section-bottom-img2">
                            <img src={img3} alt="Smart Infrastructure for swift Operations" loading='lazy' />
                        </div>
                    </div>
                </section>
            </div>

            <section className="we-offers">
                <div className="c-container">
                    <div className="we-offers-container">
                        <div className="offer-item">
                            <div className="offer-item-img">
                                <img src={logo1} alt="logo" loading='lazy' />
                            </div>
                            <p>Quality Products</p>
                        </div>

                        <div className="offer-item">
                            <div className="offer-item-img">
                                <img src={logo2} alt="logo" loading='lazy' />
                            </div>
                            <p>Best in Class Product Design</p>
                        </div>

                        <div className="offer-item">
                            <div className="offer-item-img" >
                                <img src={logo3} alt="logo" loading='lazy' />
                            </div>
                            <p>High Quality Material Used</p>
                        </div>

                        <div className="offer-item">
                            <div className="offer-item-img">
                                <img src={logo5} alt="logo" loading='lazy' />
                            </div>
                            <p>Competitive Pricing</p>
                        </div>

                        <div className="offer-item">
                            <div className="offer-item-img">
                                <img src={logo4} alt="logo" loading='lazy' />
                            </div>
                            <p>Faster Delivery</p>
                        </div>
                    </div>
                </div>
            </section>


            <WhyChooseUs />




            <section className="certificate-container">
                <div className="c-container">
                    <div className="banner-heading-section">
                        <div className="big-title">
                            <div className="big-title-content">
                                <p className='big-title-heading'>Certificates & Awards</p>
                            </div>
                        </div>
                    </div>
                    <div className="certificate-section">
                        <div className="certificate-img">
                            <img src={cert1} alt="Axitec Parternship Agreement" loading='lazy' />
                        </div>
                        <div className="certificate-img">
                            <img style={{ width: "100%" }} src={cert} alt="Certificate of apreciation" loading='lazy' />
                        </div>
                    </div>
                </div>

            </section>


            <section className="gallery-container">
                <div className="c-container">
                    <div className="banner-heading-section">
                        <div className="big-title">
                            <div className="big-title-content">
                                <p className='big-title-heading'>Gallery</p>
                            </div>
                        </div>
                    </div>
                    <div className='gallery-section'>
                        {loading ? <ColorRing
                            visible={true}
                            height="80"
                            width="80"
                            ariaLabel="color-ring-loading"
                            wrapperStyle={{}}
                            wrapperClass="color-ring-wrapper"
                            colors={['#FFCA4D', '#FFCA4D', '#FFCA4D', '#FFCA4D', '#FFCA4D',]}
                        /> :
                            data?.map((el) => {
                                return (
                                    <div className='gallery' key={el?.id}>
                                        <div className="gallery-img-div">
                                            <img className='gallery-img' src={el?.image} alt={el?.alt_tag} loading='lazy' />
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </section>


            <Testimonials />
        </>
    )
}

export default About