import React from 'react'
import "./HomeLocationBanner.css"

import { FaLocationDot } from "react-icons/fa6";
import { useForm } from 'react-hook-form';
import { HashLink } from 'react-router-hash-link';
import { useNavigate } from 'react-router-dom';

import map from "../../assets/home/map-india (3).png";
import { LazyLoadImage } from 'react-lazy-load-image-component';

const HomeLocationBannner = () => {
    const Navigate = useNavigate()
    const { register, handleSubmit, reset, formState: { errors } } = useForm();

    const scrollWithOffset = (el, offset) => {
        const elementPosition = el.getBoundingClientRect().top + window.pageYOffset - offset;
        window.scroll({
            top: elementPosition,
        });
    };

    const onSubmit = async (data) => {
        console.log(data)
        const formBody = new URLSearchParams(data).toString();
        try {
            const response = await fetch('https://kesrinandan.com/admin/api/contact-us-enquiry', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: formBody,
            });

            if (response.ok) {
                const responseData = await response.json();
                reset()
                Navigate("/thank-you")
            } else {
                console.error('Failed to submit the form');
            }
        } catch (error) {
            console.error('Error submitting the form:', error);
        }
    };

    return (
        <div className="location-banner-container">
            <div className="c-container">
                <section className='location-banner-section'>
                    <div className="location">
                        <div className="location-banner-header">
                            <p className="banner-small-title">India</p>
                            <p className="banner-big-title">Our Location</p>
                        </div>

                        <div className="loction-container">
                            <div className="area">
                                <p><FaLocationDot /> < HashLink className='location-a' to={"/location#rajasthan"} scroll={el => scrollWithOffset(el, 80)}>Rajasthan</HashLink> </p>
                                <p><FaLocationDot /> <HashLink className='location-a' to={"/location#delhi"} scroll={el => scrollWithOffset(el, 80)}>Delhi</HashLink></p>
                                <p><FaLocationDot /> <HashLink className='location-a' to={"/location#maharashtra"} scroll={el => scrollWithOffset(el, 80)}> Maharashtra</HashLink></p>
                                <p><FaLocationDot /> <HashLink className='location-a' to={"/location#madhya-pradesh"} scroll={el => scrollWithOffset(el, 80)}>Madhya Pradesh</HashLink></p>
                                <p><FaLocationDot /> <HashLink className='location-a' to={"/location#uttar-pradesh"} scroll={el => scrollWithOffset(el, 80)}>Uttar Pradesh</HashLink></p>
                            </div>
                            <div className="map">
                                <LazyLoadImage
                                    alt="Our locations"
                                    src={map}
                                    effect="blur"
                                />
                                <FaLocationDot className='map-1' />
                                <FaLocationDot className='map-2' />
                                <FaLocationDot className='map-3' />
                                <FaLocationDot className='map-4' />
                                <FaLocationDot className='map-5' />
                            </div>
                        </div>
                    </div>

                    <div className="contact-form">
                        <div className="location-banner-header">
                            <p className="banner-small-title">Conatct</p>
                            <p className="banner-big-title">Get In Touch With Us</p>

                            <form onSubmit={handleSubmit(onSubmit)} >
                                <div className="form-container">

                                    <div className="form-group">
                                        <input type="text"
                                            className='form-control form-control2'
                                            placeholder="Your Name*"
                                            {...register('name', { required: 'Required' })} />
                                        {errors.name && <div className="error-message">{errors.name.message}</div>}
                                    </div>

                                    <div className="form-group">
                                        <input type="email"
                                            className='form-control form-control2'
                                            placeholder="Email*"
                                            {...register('email', { required: 'Required', pattern: { value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i, message: 'Invalid email' } })} />
                                        {errors.email && <div className="error-message">{errors.email.message}</div>}
                                    </div>

                                    <div className="form-group">
                                        <input type="tel"
                                            className='form-control form-control2'
                                            placeholder="Phone Number*"
                                            {...register('phone', {
                                                required: 'Required', pattern: {
                                                    value: /^\+?\d{1,3}(\s?\d{6,13})?$/,
                                                    message: 'Invalid phone number',
                                                }
                                            })} />
                                        {errors.phone && <div className="error-message">{errors.phone.message}</div>}
                                    </div>


                                    <div className="form-group">
                                        <input type="text"
                                            className='form-control form-control2'
                                            placeholder='City*'
                                            {...register('city', { required: 'Required' })} />
                                        {errors.city && <div className="error-message">{errors.name.message}</div>}
                                    </div>

                                    <div className="form-group  text-area-group">
                                        <textarea className='form-control form-control2 form-control-textarea2'
                                            id="message" cols="30" rows="10"
                                            placeholder='Your Message'
                                            {...register('message', { required: 'Required' })} />
                                        {errors.message && <div className="error-message">{errors.message.message}</div>}
                                    </div>
                                </div>
                                <button className='common-btn location-btn' type="submit">Submit</button>
                            </form>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    )
}

export default HomeLocationBannner