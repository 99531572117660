import React, { useState, useEffect } from 'react';
import "./Services.css";
import BigTitle2 from '../../components/BigTitle/BigTitle2';

import { ColorRing } from 'react-loader-spinner';
import { Helmet } from 'react-helmet-async';
import Breadcrumb from '../../components/BreadCrumb/BreadCrumb';
import EnquiryModal from '../../components/EnquiryModal/EnquiryModal';


const Services = () => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [isModalOpen, setModalOpen] = useState(false);


    const openModal = () => {
        setModalOpen(true);
    };

    const closeModal = () => {
        setModalOpen(false);
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch('https://kesrinandan.com/admin/api/sector/all')
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }

                const result = await response.json();
                setData(result.data);
            } catch (error) {
                setError(error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);


    return (
        <>
            <Helmet>
                <title>Tailored Solutions for Diverse Industries | Kesrinandan Ventures</title>
                <meta
                    name="description"
                    content="Kesrinandan's sector-specific solutions are made for diverse industries. From industrial to commercial, finance to government, we provide sector challenges effectively."
                />
                <link rel="canonical" href="https://kesrinandan.com/sectors" />

                <meta property="og:url" content="https://kesrinandan.com/sectors" />
                <meta property="og:type" content="website" />
                <meta property="og:title" content="Tailored Solutions for Diverse Industries | Kesrinandan Ventures" />
                <meta property="og:description" content="Kesrinandan's sector-specific solutions are made for diverse industries. From industrial to commercial, finance to government, we provide sector challenges effectively." />
                <meta property="og:image" content="https://kesrinandan.com/static/media/services-banner.3b910e3f62ae159f62d3.webp" />

                {/* Twitter Meta Tags */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta property="twitter:domain" content="kesrinandan.com" />
                <meta property="twitter:url" content="https://kesrinandan.com/sectors" />
                <meta name="twitter:title" content="Tailored Solutions for Diverse Industries | Kesrinandan Ventures" />
                <meta name="twitter:description" content="Kesrinandan's sector-specific solutions are made for diverse industries. From industrial to commercial, finance to government, we provide sector challenges effectively." />
                <meta name="twitter:image" content="https://kesrinandan.com/static/media/services-banner.3b910e3f62ae159f62d3.webp" />


            </Helmet>
            <section className="about-banner services-banner">
                <div className="custom-text custom-text-about">
                    {/* <p className='custom-title'>Our Services<span className='color-dot'>.</span></p> */}
                    <div className="breadcrumb-hide" style={{ visibility: "hidden" }}>
                        <Breadcrumb paths={[{ label: 'Home', url: '/' }, { label: 'Sectors', url: '/sectors' }]} />
                    </div>
                </div>
            </section>
            <section className="banner-heading-section banner-heading-section2">
                <div className="c-container">
                    <BigTitle2 heading="Sectors We Serve" />
                </div>
            </section>


            <section className="services-section">
                <div className="c-container">
                    {loading ? <ColorRing
                        visible={true}
                        height="80"
                        width="80"
                        ariaLabel="color-ring-loading"
                        wrapperStyle={{}}
                        wrapperClass="color-ring-wrapper"
                        colors={['#FFCA4D', '#FFCA4D', '#FFCA4D', '#FFCA4D', '#FFCA4D',]}
                    /> :
                        data?.map((el, index) => {
                            return (
                                index % 2 === 0 ?
                                    <div className="services-card" key={el?.id} id={`sector-${index + 1}`}>
                                        <div className="services-card-img">
                                            <img src={el?.image2} alt={el?.alt_tag2} />
                                        </div>
                                        <div className="services-card-content">
                                            <h2 className='services-card-title'>{el?.title}</h2>
                                            <div className='services-card-desc' dangerouslySetInnerHTML={{ __html: `${el?.description}` }} />
                                            <button variant="primary" className="common-btn nav-btn" onClick={openModal}>Enquire Now</button>
                                        </div>
                                    </div> : <div className="services-card service-card-row-reverse" key={el?.id} id={`sector-${index + 1}`}>
                                        <div className="services-card-img">
                                            <img src={el?.image2} alt={el?.alt_tag2} />
                                        </div>
                                        <div className="services-card-content">
                                            <h2 className='services-card-title' >{el?.title}</h2>
                                            <div className='services-card-desc' dangerouslySetInnerHTML={{ __html: `${el?.description}` }} />
                                            <button variant="primary" className="common-btn nav-btn" onClick={openModal}>Enquire Now</button>
                                        </div>
                                    </div>
                            )
                        })
                    }
                </div>
            </section>
            <EnquiryModal
                isOpen={isModalOpen} onClose={closeModal}
            />
        </>
    )
}

export default Services