import React, { useEffect, useState, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { AppContext } from '../../AppContext';
import { Link } from 'react-router-dom';
import Breadcrumb from '../../components/BreadCrumb/BreadCrumb';

const SearchResults = () => {
  const { searchResults } = useContext(AppContext);
  const [filteredResults, setFilteredResults] = useState([]);
  const location = useLocation();
  const queryFromURL = new URLSearchParams(location.search).get('q');

  useEffect(() => {
    if (queryFromURL && searchResults?.length > 0) {
      const results = searchResults.filter(product =>
        product?.title?.toLowerCase().includes(queryFromURL.toLowerCase())
      );
      setFilteredResults(results);
    } else {
      setFilteredResults([]);
    }
  }, [queryFromURL, searchResults]);

  return (
    <>
      <section className="about-banner product-banner">
        <div className="custom-text custom-text-product">
          <p className='custom-title'>
            Search Result
            <span className='color-dot'>.</span>
          </p>
          <Breadcrumb paths={[{ label: 'Home', url: '/' }, { label: 'Search', url: `/search` }]} />
        </div>
      </section>

      <section className="products-section">
        <div className="c-container">
          <div>
            <h1 style={{marginBottom:"10px"}}>Search Results for "{queryFromURL}"</h1>
            {filteredResults.length > 0 ? (
              <div className="products-by-category products-container">
                {filteredResults?.map((el, index) => (
                  <div className="products-item" key={index}>
                    <Link to={`/product-detail/${el?.slug}`}>
                      <div className="products-item-img">
                        <img src={el?.image} alt="" />
                      </div>
                      <h5>{el?.title}</h5>
                    </Link>
                  </div>
                ))}
              </div>
            ) : (
              <p>No results found</p>
            )}
          </div>
        </div>
      </section>

    </>
  );
};

export default SearchResults;
