import React, { useState } from 'react';
import './CtaSection.css';

import cta45 from "../../assets/Frame 45.png";
import cta46 from "../../assets/Frame 46.png";
import cta47 from "../../assets/Frame 47.png";

import { Link } from 'react-router-dom';
import EnquiryModal from '../EnquiryModal/EnquiryModal';

const CtaSection = () => {
  const [isModalOpen, setModalOpen] = useState(false);

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const ctaData = [
    {
      title: 'Competitive Prices',
      description: 'Unlock unbeatable deals on all products sourced directly from trusted manufacturers. Transform your industrial or commercial space with the best value on the market.',
      buttonText: "Let's Talk",
      image: cta47,
      link: 'https://kesrinandan.com/contact',
      bgColor: '#A78759'
    },
    {
      title: 'Become a Dealer',
      description: 'Join Kesrinandan for exclusive access, unbeatable prices, and a prestigious brand partnership. Elevate your business’s competitive edge.',
      buttonText: 'Contact us',
      image: cta46,
      link: 'https://kesrinandan.com/become-partner',
      bgColor: '#4D2C20'
    },
    {
      title: 'High-Quality Materials Used',
      description: 'Experience the quality difference. We use only the finest materials to design our products ensuring unmatched durability and long-term performance. ',
      buttonText: 'Get a Quote',
      image: cta45,
      link: '',
      bgColor: '#005248'
    }
  ];

  return (
    <>
      <EnquiryModal isOpen={isModalOpen} onClose={closeModal} />
      <section className="cta-section">
        {ctaData.map((cta, index) => (
          <div className="cta-card" key={index} style={{ backgroundColor: cta.bgColor }} id={`cta-card-${index + 1}`}>
            <div className="cta-content">
              <h2>{cta.title}</h2>
              <p>{cta.description}</p>
              <Link to={cta.link} className="cta-button common-btn" onClick={index === 2 ? openModal : null}>
                {cta.buttonText}
              </Link>
            </div>
            {/* <div className="cta-image">
              <img src={cta.image} alt={cta.title} />
            </div> */}
          </div>
        ))}
      </section>
    </>
  );
};

export default CtaSection;
