import React from 'react';
import './AuthorSection.css';

import img from "../../assets/Rohit_Pareta2.png";

import icon1 from "../../assets/instagram.svg";
import icon2 from "../../assets/x.svg";
import icon3 from "../../assets/linkedin.svg";
import { Link } from 'react-router-dom';



const AuthorSection = () => {
    return (
        <div className="author-section">
            <div className="author-image">
                <img src={img} alt="Rohit Pareta" />
                <div className="social-connect">
                    <p>Social Connect:</p>
                    <div>
                        <a href="https://www.instagram.com/rohitcj2024/"><img src={icon1} alt="Instagram" /></a>
                        <a href="https://in.linkedin.com/in/rohit-pareta-44338b22 "><img src={icon3} alt="LinkedIn" /></a>
                        <a href="https://x.com/rohitpareta "><img src={icon2} alt="Twitter" /></a>
                    </div>
                </div>
            </div>
            <div className="author-details">
                <h4>Author: Rohit Pareta</h4>
                <p>A visionary leader and an expert in the field of renewable energy with over 12 years of experience.</p>
                <p>With his remarkable leadership qualities and a profound understanding of the renewable energy landscape, Mr. Pareta took Kesrinandan Ventures LLP, a leading solar panel and <Link to="/">solar system distributor</Link>, to new heights of success.</p>
                <p>Born in Kota, Rajasthan, he holds a Master of Business Administration degree and serves as the Secretary and Treasurer of BNI Tathastu.</p>

            </div>
        </div>
    );
};

export default AuthorSection;
