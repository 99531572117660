import React from 'react';
import "./Career.css";
import BigTitle2 from '../../components/BigTitle/BigTitle2';
import { Helmet } from 'react-helmet-async';
import Breadcrumb from '../../components/BreadCrumb/BreadCrumb';


const Career = () => {
    return (
        <>
            <Helmet>
                <title>Get Your Path to Success With Kesrinandan Ventures</title>
                <meta
                    name="description"
                    content="Discover exciting career prospects and hiring opportunities at Kesrinandan. Explore our current openings & start your journey towards a rewarding career today."
                />

                <link rel="canonical" href="https://kesrinandan.com/career" />

                {/* Facebook Meta Tags */}
                <meta property="og:url" content="https://kesrinandan.com/career" />
                <meta property="og:type" content="website" />
                <meta property="og:title" content="Get Your Path to Success With Kesrinandan Ventures" />
                <meta property="og:description" content="Discover exciting career prospects and hiring opportunities at Kesrinandan. Explore our current openings & start your journey towards a rewarding career today." />
                <meta property="og:image" content="https://kesrinandan.com/static/media/career-banner.c30f7b24d55197c4412a.png" />

                {/* Twitter Meta Tags */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta property="twitter:domain" content="kesrinandan.com" />
                <meta property="twitter:url" content="https://kesrinandan.com/career" />
                <meta name="twitter:title" content="Get Your Path to Success With Kesrinandan Ventures" />
                <meta name="twitter:description" content="Discover exciting career prospects and hiring opportunities at Kesrinandan. Explore our current openings & start your journey towards a rewarding career today." />
                <meta name="twitter:image" content="https://kesrinandan.com/static/media/career-banner.c30f7b24d55197c4412a.png" />

            </Helmet>
            
            <section className="about-banner career-banner">
                <div className="custom-text custom-text-about">
                    <div className="breadcrumb-hide" style={{ visibility: "hidden" }}>
                        <Breadcrumb paths={[{ label: 'Home', url: '/' }, { label: 'Career', url: '/career' }]} />
                    </div>
                </div>
            </section>
            <section className="banner-heading-section banner-heading-section2">
                <div className="c-container">
                    <BigTitle2 heading="Careers" />
                </div>
            </section>

            <section className="career-section">
                <div className="c-container">
                    <h2 className="description">
                        We always welcome young, enthusiastic and willing-to-learn people to join our team.
                    </h2>
                    <div className="career-card-container">
                        <div className="career-card">
                            <h3>Marketing Manager</h3>
                            <h6>Jaipur | Full Time</h6>
                            <ul className="career-roles">
                                <li>Planning and overseeing new marketing initiatives.</li>
                                <li>Support sales organization in delivering client satisfaction through influence management.</li>
                                <li>Co-creation and articulating customer value propositions.</li>
                                <li>Prepare monthly updates including competitor activity, new business, policy updates etc.</li>
                                <li>Represent at industry events, including round tables, speaking forums &amp; others events.</li>
                                <li>Develop a growth strategy focused both on financial gain and customer satisfaction.</li>
                                <li>Prepare sales contracts ensuring adherence to law-established rules and guidelines.</li>
                            </ul>
                            <a href="mailto:info@kesrinandan.com?subject=Application For Solar Engineer" className='common-btn career-btn'>Apply Now</a>
                        </div>
                        <div className="career-card">
                            <h3>Civil Engineer</h3>
                            <h6>Kota | Full Time</h6>
                            <ul className="career-roles">
                                <li>Create drawings for construction projects.</li>
                                <li>Draw topographical & relief map to aid construction workers & architects.</li>
                                <li>Create visual guidelines for construction purposes.</li>

                            </ul>
                            <a href="mailto:info@kesrinandan.com?subject=Application For Solar Engineer" className='common-btn career-btn'>Apply Now</a>
                        </div>
                        <div className="career-card">
                            <h3>Junior Accountant</h3>
                            <h6>Jaipur | Full Time</h6>
                            <ul className="career-roles">
                                <li>1 - 2 years of experience in a similar position.</li>
                                <li>Educational background in accounting. </li>
                                <li>Advanced knowledge of Microsoft Excel; experience with formulas and pivot tables is highly beneficial.</li>
                                <li>Analytical skills and logical thinking.</li>
                                <li>Diligence, reliability, and attention to detail</li>
                            </ul>
                            <a href="mailto:info@kesrinandan.com?subject=Application For Solar Engineer" className='common-btn career-btn'>Apply Now</a>
                        </div>
                        <div className="career-card">
                            <h3>Administrative Specialty</h3>
                            <h6>Jaipur | Part Time</h6>
                            <ul className="career-roles">
                                <li>Managing the smooth flow of company documentation, including dealing with incoming and outgoing correspondence and handling couriers</li>
                                <li>Managing meeting room reservations, organizing business meetings, and welcoming visitors.</li>
                                <li>Contacting service providers.</li>
                                <li>Answering incoming phone calls.</li>
                                <li>Providing administrative support in matters related to facilities, fleets, HR, marketing and IT</li>
                            </ul>
                            <a href="mailto:info@kesrinandan.com?subject=Application For Solar Engineer" className='common-btn career-btn'>Apply Now</a>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Career