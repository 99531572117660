import React, { useEffect, useState, Suspense, useContext } from 'react';
import "./EnquiryModal.css"
import { useForm, Controller } from 'react-hook-form';
import { RxCross2 } from "react-icons/rx";
import { useNavigate } from 'react-router-dom';
import { AppContext } from '../../AppContext';
const ReCAPTCHA = React.lazy(() => import('react-google-recaptcha'));

const EnquiryModal = ({ isOpen, onClose, children }) => {
    const { control, register, handleSubmit, reset, formState: { errors }, watch, setValue } = useForm({
        defaultValues: {
            enquiry_type: '0'
        }
    });

    const { states, city, setSelectedState } = useContext(AppContext);
    const [showReCAPTCHA, setShowReCAPTCHA] = useState(false);
    const [captchaError, setCaptchaError] = useState(null);
    const [captchaToken, setCaptchaToken] = useState(null);
    const navigate = useNavigate()

    const handleFormInteraction = () => {
        if (!showReCAPTCHA) {
            setShowReCAPTCHA(true);
        }
    };

    const handleVerificationSuccess = (token) => {
        setCaptchaToken(token);
    };

    const selectedState = watch('state')

    useEffect(() => {
        setSelectedState(selectedState);
      }, [selectedState, setSelectedState]);

    const handleOverlayClick = (event) => {
        if (event.target === event.currentTarget) {
            onClose();
        }
    };


    const onSubmit = async (data) => {
        data.captchaToken = captchaToken;
        const formBody = new URLSearchParams(data).toString();
        try {
            const response = await fetch('https://kesrinandan.com/admin/api/quote-enquiry', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: formBody,
            });

            const responseData = await response.json();

            if (response.ok) {
                reset()
                onClose()
                navigate("/thank-you")
            } else {
                if (responseData.msg && responseData.msg === "Invalid CAPTCHA. Please try again") {
                    setCaptchaError(responseData.msg);
                } else {
                    console.error('Failed to submit the form:', responseData.error);
                }
            }
        } catch (error) {
            console.error('Error submitting the form:', error);
        }
    };


    return (
        <>
            <div className="modal" style={{ display: isOpen ? "block" : "none" }} onClick={handleOverlayClick}>
                <div className="modal-diologue">
                    <div className="modal-container">
                        <div className="modal-header">
                            <p className='m-h'>Service Request</p>
                            <button className="close-button" onClick={onClose}><RxCross2 /></button>
                        </div>
                        <div className="popup-modal">
                            <div className="modal-content">
                                <form onFocus={handleFormInteraction} onClick={handleFormInteraction} onSubmit={handleSubmit(onSubmit)} className="vertical-form">
                                    <div className="form-container form-container2">

                                        <div className="form-group">
                                            <label>Your Name</label>
                                            <input type="text" className='form-control' placeholder='Your name' {...register('name', { required: 'Required' })} />
                                            {errors.name && <div className="error-message">{errors.name.message}</div>}
                                        </div>

                                        <div className="form-group">
                                            <label>Your Email</label>
                                            <input type="email" className='form-control' placeholder='Email' {...register('email', { required: 'Required', pattern: { value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i, message: 'Invalid email' } })} />
                                            {errors.email && <div className="error-message">{errors.email.message}</div>}
                                        </div>

                                        <div className="form-group">
                                            <label>Your Number</label>
                                            <input type="tel" className='form-control' placeholder='Phone number' {...register('phone', {
                                                required: 'Required', pattern: {
                                                    value: /^\+?\d{1,3}(\s?\d{6,13})?$/,
                                                    message: 'Invalid phone number',
                                                }
                                            })} />
                                            {errors.phone && <div className="error-message">{errors.phone.message}</div>}
                                        </div>

                                        <div className="form-group">
                                            <label>Select Service</label>
                                            <Controller
                                                name="service_name"
                                                defaultValue={""}
                                                control={control}
                                                rules={{ required: 'Please select a service' }}
                                                render={({ field }) => (
                                                    <select {...field} className='form-control' onChange={(e) => setValue('service_name', e.target.value)}>
                                                        <option value="" disabled>Select a service</option>
                                                        <option value="Solar Panels">Solar Panels</option>
                                                        <option value="Solar Panels">Inverters</option>
                                                        <option value="Solar Panels">Cable Wires</option>
                                                        <option value="Solar Panels">PPA</option>
                                                    </select>
                                                )}
                                            />
                                            {errors.service_name && <div className="error-message">{errors.service_name.message}</div>}

                                        </div>

                                        <div className="form-group">
                                            <label htmlFor="stateSelect">State</label>
                                            <Controller
                                                name="state"
                                                defaultValue={""}
                                                control={control}
                                                rules={{ required: 'Please select a state' }}
                                                render={({ field }) => (
                                                    <select {...field} className='form-control' onChange={(e) => setValue('state', e.target.value)}>
                                                        <option value="" disabled>Select a state</option>
                                                        {states?.map((el) => {
                                                            return (
                                                                <option key={el?.id} value={el?.id}>
                                                                    {el?.state}
                                                                </option>
                                                            )
                                                        })}
                                                    </select>
                                                )}
                                            />
                                            {errors.state && <div className="error-message">{errors.state.message}</div>}
                                        </div>

                                        <div className="form-group">
                                            <label htmlFor="citySelect">City</label>
                                            <Controller
                                                name="city"
                                                defaultValue={""}
                                                control={control}
                                                rules={{
                                                    required: 'Please select a city'
                                                }}

                                                render={({ field }) => (
                                                    <select {...field} className='form-control' onChange={(e) => setValue('city', e.target.value)}>
                                                        <option value="" disabled>Select a city</option>
                                                        {city?.map((el) => {
                                                            return (
                                                                <option key={el?.id} value={el?.id}>
                                                                    {el?.city_name}
                                                                </option>
                                                            )
                                                        })}
                                                    </select>
                                                )}
                                            />
                                            {errors.city && <div className="error-message">{errors.city.message}</div>}
                                        </div>

                                        <div className="form-group type-area-group">
                                            <label htmlFor="typeSelect">Select Type</label>
                                            <Controller
                                                name="type"
                                                defaultValue={""}
                                                control={control}
                                                rules={{
                                                    required: 'Please select a type'
                                                }}

                                                render={({ field }) => (
                                                    <select {...field} className='form-control' onChange={(e) => setValue('type', e.target.value)}>
                                                        <option value="" disabled>Select a type</option>
                                                        <option value="dealer">Dealer</option>
                                                        <option value="retailer">Retailer</option>
                                                        <option value="distributor">Distributor</option>
                                                        <option value="epc player">EPC Player</option>
                                                        <option value="consumer">Consumer</option>
                                                    </select>
                                                )}
                                            />
                                            {errors.type && <div className="error-message">{errors.type.message}</div>}
                                        </div>

                                        <div className="form-group text-area-group" >
                                            <textarea placeholder='Your message' className='form-control form-control-textarea' {...register('message', { required: 'Required' })} />
                                            {errors.message && <div className="error-message">{errors.message.message}</div>}
                                        </div>

                                        <input type="hidden" {...register('enquiry_type')} />

                                        <div className="form-group">
                                            {showReCAPTCHA && (
                                                <Suspense fallback={<div>Loading reCAPTCHA...</div>}>
                                                    <ReCAPTCHA
                                                        sitekey="6Lc5zpQpAAAAAO9suPoYtJaeB5r57wNwKbkCbSjX"
                                                        onChange={handleVerificationSuccess}
                                                    />
                                                </Suspense>
                                            )}

                                            {captchaError && <div className="error-message">{captchaError}</div>}
                                        </div>

                                    </div>
                                    <div className="form-footer">
                                        <button className='common-btn enquiry-btn' type="submit">Submit Request</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>

    )
}

export default EnquiryModal