import React from 'react';
import "./WhyChooseUs.css";
import SmallTitle from '../SmallTitle/SmallTitle';
import Description from '../Description/Description';

import Qns from "../../assets/about/Group 96.svg";
import group1 from "../../assets/about/Group 54.svg";
import group2 from "../../assets/about/Group 55.svg";
import group3 from "../../assets/about/Group 56.svg";
import group4 from "../../assets/about/Group 57.svg";
import group5 from "../../assets/about/Group 58.svg";
import group6 from "../../assets/about/Group 59.svg";
import WhyChooseItem from './WhyChooseItem';


const WhyChooseUs = () => {
    return (
        <div className="c-container">
            <section className="why-choose-us">

                <SmallTitle desc="OUR BENEFITS" />
                <div className="big-title">
                    <div className="big-title-content">
                        <h3 className='big-title-heading'>Main Reasons Why Choose Us</h3>
                    </div>
                    <div className="big-title-img">
                        <img src={Qns} alt={"Have Questions?"} />
                    </div>
                </div>
                <Description desc="Harnessing the sun, powering a brighter future" />
                <div className="why-choose-us-container">
                    <WhyChooseItem img={group1} heading="Kesrinandan Solar Assured" desc="We provide our customers with peace of mind by guaranteeing the quality, dependability, and performance of our solar solutions." />
                    <WhyChooseItem img={group3} heading="Kesrinandan Solar Finance" desc="We make solar energy accessible and cheap for everyone by providing customized and adaptable financing alternatives." />
                    <WhyChooseItem img={group4} heading="Kesrinandan Assurance Guarantee" desc="Under our assurance guarantee, we will stand behind our goods and services and offer thorough help and support the whole way." />
                    <WhyChooseItem img={group2} heading="Demand-Driven Response" desc="Understanding and meeting the needs of each individual customer is our first focus in order to ensure that our solutions meet or exceed their expectations." />
                    <WhyChooseItem img={group5} heading="Commercial-grade Equipment" desc="Our solar systems are powered by top-notch, commercial-grade equipment that is designed to withstand rigorous use and deliver optimal performance to businesses and organizations." />
                    <WhyChooseItem img={group6} heading="Solar Solutions for Rooftops" desc="With our rooftop solar solutions, customers can generate electricity straight from their roofs, saving them money and reducing their carbon impact. They also offer a practical and sustainable way to use solar energy." />
                </div>

            </section>
        </div>
    )
}

export default WhyChooseUs