import React, { useState } from 'react';
import "./Ppa.css";
import { Helmet } from 'react-helmet-async';


import BigTitle2 from '../../components/BigTitle/BigTitle2';
import Breadcrumb from '../../components/BreadCrumb/BreadCrumb';

import ppa1 from "../../assets/ppa/ppa-img2.webp";
import ppa2 from "../../assets/ppa/ppa-img3.webp";
import ppa3 from "../../assets/ppa/ppa-img4.webp";
import ppa4 from "../../assets/ppa/ppa-img5.webp";
import ppa5 from "../../assets/ppa/ppa-img6.webp";
import ppa6 from "../../assets/ppa/ppa-img7.webp";

import EnquiryModal from '../../components/EnquiryModal/EnquiryModal';


const Ppa = () => {
    const [isModalOpen, setModalOpen] = useState(false);


    const openModal = () => {
        setModalOpen(true);
    };

    const closeModal = () => {
        setModalOpen(false);
    };
    return (
        <>
            <Helmet>
                <title>Solar Power Purchase Agreements (PPA) | Kesrinandan</title>
                <meta
                    name="description"
                    content=" Discover the benefits of Power Purchase Agreements (PPA) solar solutions with Kesrinandan. Maximize energy savings and sustainability with our PPA options. Call now!"
                />
                <link rel="canonical" href="https://kesrinandan.com/ppa" />

                <meta property="og:url" content="https://kesrinandan.com/ppa" />
                <meta property="og:type" content="website" />
                <meta property="og:title" content="Power Purchase Agreement (PPAs): Kesrinandan Ventures" />
                <meta property="og:description" content="Discover the benefits of Power Purchase Agreements (PPA) solar solutions with Kesrinandan. Maximize energy savings and sustainability with our PPA options. Call now!" />
                <meta property="og:image" content="https://kesrinandan.com/static/media/ppa-banner.8fecea0159f679ad1f77.webp" />

                {/* Twitter Meta Tags */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta property="twitter:domain" content="kesrinandan.com" />
                <meta property="twitter:url" content="https://kesrinandan.com/ppa" />
                <meta name="twitter:title" content="Power Purchase Agreement (PPAs): Kesrinandan Ventures" />
                <meta name="twitter:description" content="Discover the benefits of Power Purchase Agreements (PPA) solar solutions with Kesrinandan. Maximize energy savings and sustainability with our PPA options. Call now!" />
                <meta name="twitter:image" content="https://kesrinandan.com/static/media/ppa-banner.8fecea0159f679ad1f77.webp" />

            </Helmet>

            <section className="about-banner ppa-banner">
                <div className="custom-text custom-text-about">
                    <p className='custom-title'>PPA<span className='color-dot'>.</span></p>
                    <Breadcrumb paths={[{ label: 'Home', url: '/' }, { label: 'PPA', url: '/ppa' }]} />
                </div>
            </section>

            <section className="ppa-page-content">
                <div className="c-container">
                    <div className="ppa-page-sec1 flex-column-reverse">
                        <div className="page-content-left right-pad">
                            <h1 className='ppa-page-sec1-title'>What is PPA?</h1>
                            <p className='ppa-page-sec1-desc'>An energy producer and an energy consumer enter into a contract known as a Power Purchase Agreement (PPA). It describes how energy will be produced and sold to the buyer over a predetermined time frame. PPA agreements are frequently utilized in renewable energy projects, especially those using wind and solar energy.</p>
                        </div>
                        <div className="page-content-right">
                            <img src={ppa1} alt="ppa" />
                        </div>
                    </div>
                    <div className="ppa-page-sec1">
                        <div className="page-content-right">
                            <img src={ppa2} alt="ppa" />
                        </div>
                        <div className="page-content-left left-pad">
                            <h3 className='ppa-page-sec1-title'>How Does PPA Solar Function?</h3>
                            <p className='ppa-page-sec1-desc'>PPA Solar operates by enabling companies to buy power produced by the sun without having to make investments in solar infrastructure. The solar panels are installed and maintained by solar panel distributors, and the company commits to purchasing the generated electricity at a fixed price. With this configuration, businesses can profit from solar electricity without having to deal with up-front expenditures and maintenance issues.</p>
                        </div>
                    </div>
                    <div className="ppa-page-sec1 flex-column-reverse">
                        <div className="page-content-left right-pad">
                            <h3 className='ppa-page-sec1-title'>What Are PPAs Used For?</h3>
                            <p className='ppa-page-sec1-desc'>Projects related to renewable energy are the main usage of PPAs. They give developers a reliable source of income and give consumers a set price for sustainable energy PPA. Utilities, companies, and governments utilize PPAs to save energy costs and achieve sustainability goals.</p>
                        </div>
                        <div className="page-content-right">
                            <img src={ppa3} alt="ppa" />
                        </div>
                    </div>
                    <div className="ppa-page-sec1">
                        <div className="page-content-right">
                            <img src={ppa4} alt="ppa" />
                        </div>
                        <div className="page-content-left left-pad">
                            <h3 className='ppa-page-sec1-title'>Who Needs A PPA Agreement?</h3>
                            <p className='ppa-page-sec1-desc'>A PPA agreement can be advantageous to any organization that wants to buy renewable energy without having to own and manage the energy-generating facilities. This includes big and small companies, governments, schools, and other organizations trying to meet energy-saving goals and reach sustainability standards.</p>
                        </div>
                    </div>
                </div>
            </section>

            <div className="ppa-page-sec2" style={{ backgroundImage: `url(${ppa5})` }}>
                <div className="c-container" >
                    <h2>Different Types Of Power Purchase Agreements</h2>
                    <div className="ppa-page-sec2-cards">
                        <div className="ppa-page-sec2-card">
                            <h3>Physical PPA</h3>
                            <p>The generator delivers electricity to the buyer physically.</p>
                        </div>
                        <div className="ppa-page-sec2-card">
                            <h3>Virtual PPA (VPPA)</h3>
                            <p>Instead of receiving actual electricity, the buyer receives financial gain from the project's renewable energy credits.</p>
                        </div>
                        <div className="ppa-page-sec2-card">
                            <h3>Sleeved PPA</h3>
                            <p>Involves using a third-party tool to "sleeve" or expedite the buyer-generator transaction.</p>
                        </div>
                    </div>
                </div>
            </div>


            <div className="ppa-page-sec3">
                <div className="c-container">
                    <div className="ppa-page-sec3-content">
                        <h2>How Can a PPA Solar Agreement Benefit Your Business?</h2>
                        <p>By offering long-term, consistent energy costs, lowering exposure to variable energy prices, and assisting in the achievement of corporate sustainability goals, a Power Purchase solar agreement can greatly benefit your company. It also frees up capital and operating expenses so that your company can employ renewable energy.</p>
                        <button variant="primary" className="common-btn nav-btn" onClick={openModal} style={{ marginTop: "10px" }}>Enquire Now</button>
                    </div>
                    <div className="ppa-page-sec3-img">
                        <img src={ppa6} alt="" />
                    </div>

                </div>
            </div>
            <EnquiryModal
                isOpen={isModalOpen} onClose={closeModal}
            />
        </>
    )
}

export default Ppa