import React, { useState, useEffect, useContext } from 'react';
import "./BrandDetail.css"
import { useParams } from 'react-router-dom';
import { ColorRing } from 'react-loader-spinner';
import { Helmet } from 'react-helmet-async';
import Breadcrumb from '../../components/BreadCrumb/BreadCrumb';
import CtaSection from '../../components/CtaSection/CtaSection';
import * as Accordion from '@radix-ui/react-accordion';
import classNames from 'classnames';
import { FaPlus, FaMinus } from "react-icons/fa";
import { Link, useNavigate } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import ReCAPTCHA from 'react-google-recaptcha';


import NotFoundPage from '../../components/NotFoundPage/NotFoundPage';
import { AppContext } from '../../AppContext';

import Axitec from "../../assets/brands/axitec-banner.webp";
import Rec from "../../assets/brands/rec-banner.webp";
import Livguard from "../../assets/brands/livguard-banner.webp";
import Panasonic from "../../assets/brands/panasonic-banner.png";
import Eastman from "../../assets/brands/eastman-banner.webp";
import DefaultImg from "../../assets/brand-banner.png";

const BrandDetail = () => {

    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [captchaError, setCaptchaError] = useState(null);
    const [captchaToken, setCaptchaToken] = useState(null);
    const Navigate = useNavigate()
    const { slug } = useParams()
    const { control, register, handleSubmit, reset, formState: { errors }, watch, setValue } = useForm({
        defaultValues: {
            enquiry_type: '0'
        }
    });
    const { states, city, setSelectedState } = useContext(AppContext);
    const selectedState = watch('state')
    useEffect(() => {
        setSelectedState(selectedState);
    }, [selectedState, setSelectedState]);

    const handleVerificationSuccess = (token) => {
        setCaptchaToken(token);
    };

    const images = {
        axitec: Axitec,
        livguard: Livguard,
        rec: Rec,
        panasonic: Panasonic,
        eastman: Eastman,
    };

    const defaultImage = DefaultImg;

    const imageSrc = images[slug] || defaultImage;

    const AccordionTrigger = React.forwardRef(({ children, className, ...props }, forwardedRef) => (
        <Accordion.Header className="AccordionHeader">
            <Accordion.Trigger
                className={classNames('AccordionTrigger', className)}
                {...props}
                ref={forwardedRef}
            >
                {children}
                <FaPlus className={` toggle-on`} />
                <FaMinus className={`toggle-off`} />
            </Accordion.Trigger>
        </Accordion.Header>
    ));

    const AccordionContent = React.forwardRef(({ children, className, ...props }, forwardedRef) => (
        <Accordion.Content
            className={classNames('AccordionContent', className)}
            {...props}
            ref={forwardedRef}
        >
            <div className="AccordionContentText">{children}</div>
        </Accordion.Content>
    ));

    const groupProductsByCategoryId = (products) => {
        const grouped = products.reduce((acc, product) => {
            const categoryId = product.category_id;
            if (!acc[categoryId]) {
                acc[categoryId] = [];
            }
            acc[categoryId].push(product);
            return acc;
        }, {});
        return grouped;
    };

    const renderProductsByCategoryId = (products) => {
        const groupedProducts = groupProductsByCategoryId(products);
        const categoryNames = {
            108: 'Battery',
            29: 'Inverter',
            30: 'Solar Panel',
        };

        return Object.keys(groupedProducts).map((categoryId, index) => (
            <div key={index}>
                <h2 className='product-ct'>{categoryNames[categoryId]}</h2>
                <div className='brand-products'>
                    {groupedProducts[categoryId].map((product) => (
                        <div className="products-item" key={product.id}>
                            <Link to={`/product-detail/${product.slug}`}>
                                <div className="products-item-img">
                                    <img src={product.image} alt="" />
                                </div>
                                <h5>{product.title}</h5>
                            </Link>
                        </div>
                    ))}
                </div>
            </div>
        ));
    };


    useEffect(() => {
        const fetchData = async () => {
            setError(null);
            try {
                const response = await fetch(`https://kesrinandan.com/admin/api/partner/details?slug=${slug}`)
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }

                const result = await response.json();
                if (!result?.data) {
                    throw new Error('Invalid slug');
                }
                setData(result?.data);
            } catch (error) {
                setError(error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [slug]);

    if (error) {
        return <NotFoundPage />;
    }

    const onSubmit = async (data) => {
        data.captchaToken = captchaToken;
        const formBody = new URLSearchParams(data).toString();
        try {
            const response = await fetch('https://kesrinandan.com/admin/api/quote-enquiry', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: formBody,
            });
            const responseData = await response.json();
            if (response.ok) {
                reset()
                Navigate("/thank-you")
            } else {
                if (responseData.msg && responseData.msg === "Invalid CAPTCHA. Please try again") {
                    setCaptchaError(responseData.msg);
                } else {
                    console.error('Failed to submit the form:', responseData.error);
                }
            }
        } catch (error) {
            console.error('Error submitting the form:', error);
        }
    };

    return (
        <>
            <Helmet>
                <title>{data?.meta_title}</title>
                <meta
                    name="description"
                    content={data?.meta_description}
                />
                <link rel="canonical" href={`https://kesrinandan.com/brand-detail/${slug}`} />

                {/* Facebook Meta Tags */}
                <meta property="og:url" content={`https://kesrinandan.com/brand-detail/${slug}`} />
                <meta property="og:type" content="website" />
                <meta property="og:title" content={data?.meta_title} />
                <meta property="og:description" content={data?.meta_description} />
                <meta property="og:image" content="https://kesrinandan.com/static/media/brand-detail-banner.9346f140c12dd52ee73d.webp" />

                {/* Twitter Meta Tags */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta property="twitter:domain" content="kesrinandan.com" />
                <meta property="twitter:url" content={`https://kesrinandan.com/brand-detail/${slug}`} />
                <meta name="twitter:title" content={data?.meta_title} />
                <meta name="twitter:description" content={data?.meta_description} />
                <meta name="twitter:image" content="https://kesrinandan.com/static/media/brand-detail-banner.9346f140c12dd52ee73d.webp" />

            </Helmet>


            <section className="brands-detail-banner">
                <div className="brand-detail-img">
                    <img src={imageSrc} alt={`${slug}`} />
                </div>
                <div className="custom-text custom-text-about">
                    <p className='custom-title' style={{ textTransform: "capitalize" }}>
                        {slug}
                        {slug && <span className='color-dot'>.</span>}
                    </p>
                    <Breadcrumb paths={[
                        { label: 'Home', url: '/' },
                        { label: `${slug}`, url: `/brand-detail/${slug}` }
                    ]} />
                </div>
            </section>

            <section className="brand-detail-section">

                {loading ? <ColorRing
                    visible={true}
                    height="80"
                    width="80"
                    ariaLabel="color-ring-loading"
                    wrapperStyle={{}}
                    wrapperClass="color-ring-wrapper"
                    colors={['#FFCA4D', '#FFCA4D', '#FFCA4D', '#FFCA4D', '#FFCA4D',]}
                /> : (
                    <>
                        <div>
                            <div className="brand-logo">
                                <h2>{data?.title}</h2>
                            </div>
                            <div className="brands-desc">
                                < div dangerouslySetInnerHTML={{ __html: `${data?.top_description}` }} />
                            </div>
                        </div>
                        {slug === 'livguard' || slug === 'eastman' ? (
                            <>
                                {renderProductsByCategoryId(data?.products_data)}
                            </>
                        ) : (
                            <div className='brand-products'>{
                                data?.products_data?.map((el) => (
                                    <div className="products-item" key={el?.id}>
                                        <Link to={`/product-detail/${el?.slug}`}>
                                            <div className="products-item-img">
                                                <img src={el?.image} alt="" />
                                            </div>
                                            <h5>{el?.title}</h5>
                                        </Link>
                                    </div>
                                ))
                            }</div>
                        )}

                        <CtaSection />

                        <div className="brands-desc brand-desc-2">
                            {loading ? <ColorRing
                                visible={true}
                                height="80"
                                width="80"
                                ariaLabel="color-ring-loading"
                                wrapperStyle={{}}
                                wrapperClass="color-ring-wrapper"
                                colors={['#FFCA4D', '#FFCA4D', '#FFCA4D', '#FFCA4D', '#FFCA4D',]}
                            /> : < div dangerouslySetInnerHTML={{ __html: `${data?.bottom_description}` }} />
                            }
                        </div>

                    </>
                )}


            </section>

            <section className="faq-section">
                <div className="c-container">
                    <h2 className="description">If you have any questions, we will be very happy to answer them</h2>
                    <div className="faq-accordion-section">
                        <Accordion.Root className="AccordionRoot" type="single" collapsible>
                            {data?.dataAccordian?.map((el, index) => (
                                <Accordion.Item className="AccordionItem" key={index + 1} value={index + 1}>
                                    <AccordionTrigger>{el?.title}</AccordionTrigger>
                                    <AccordionContent>{el?.description}</AccordionContent>
                                </Accordion.Item>
                            ))}
                        </Accordion.Root>
                    </div>
                </div>
            </section>

            <section className="inner-contact-form">
                <div className="inner-contact-form-content c-container">
                    <div className="inner-contact-form-heading">
                        Contact Us
                    </div>
                    <form onSubmit={handleSubmit(onSubmit)} className="vertical-form">
                        <div className="form-container">
                            <div className="form-group">
                                <input type="text" className='form-control' placeholder='Your name' {...register('name', { required: 'Required' })} />
                                {errors.name && <label className="error-message">{errors.name.message}</label>}
                            </div>

                            <div className="form-group">
                                <input type="email" className='form-control' placeholder='Email' {...register('email', { required: 'Required', pattern: { value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i, message: 'Invalid email' } })} />
                                {errors.email && <label className="error-message">{errors.email.message}</label>}
                            </div>

                            <div className="form-group">
                                <input type="tel" className='form-control' placeholder='Phone number' {...register('phone', {
                                    required: 'Required', pattern: {
                                        value: /^\+?\d{1,3}(\s?\d{6,13})?$/,
                                        message: 'Invalid phone number',
                                    }
                                })} />
                                {errors.phone && <label className="error-message">{errors.phone.message}</label>}
                            </div>

                            <div className="form-group">
                                <Controller
                                    name="service_name"
                                    defaultValue={""}
                                    control={control}
                                    rules={{ required: 'Please select a service' }}
                                    render={({ field }) => (
                                        <select {...field} className='form-control' onChange={(e) => setValue('service_name', e.target.value)}>
                                            <option value="" disabled>Select a service</option>
                                            <option value="Solar Panels">Solar Panels</option>
                                            <option value="Solar Panels">Inverters</option>
                                            <option value="Solar Panels">Cable Wires</option>
                                            <option value="Solar Panels">PPA</option>
                                        </select>
                                    )}
                                />
                                {errors.service_name && <label className="error-message">{errors.service_name.message}</label>}

                            </div>

                            <div className="form-group">
                                <Controller
                                    name="state"
                                    defaultValue={""}
                                    control={control}
                                    rules={{ required: 'Please select a state' }}
                                    render={({ field }) => (
                                        <select
                                            className='form-control'
                                            {...field} onChange={(e) => setValue('state', e.target.value)}>
                                            <option value="" disabled>State</option>
                                            {states?.map((el) => {
                                                return (
                                                    <option key={el?.id} value={el?.id}>
                                                        {el?.state}
                                                    </option>
                                                )
                                            })}
                                        </select>
                                    )}
                                />
                                {errors.state && <label className="error-message">{errors.state.message}</label>}

                            </div>

                            <div className="form-group">
                                <Controller
                                    name="city"
                                    defaultValue={""}
                                    control={control}
                                    rules={{ required: 'Please select a city' }}
                                    render={({ field }) => (
                                        <select
                                            className='form-control'
                                            {...field} onChange={(e) => setValue('city', e.target.value)}>
                                            <option value="" disabled>City</option>
                                            {city?.map((el) => {
                                                return (
                                                    <option key={el?.id} value={el?.id}>
                                                        {el?.city_name}
                                                    </option>
                                                )
                                            })}
                                        </select>
                                    )}
                                />
                                {errors.city && <label className="error-message">{errors.city.message}</label>}
                            </div>

                            <div className="form-group">
                                <Controller
                                    name="type"
                                    defaultValue={""}
                                    control={control}
                                    rules={{
                                        required: 'Please select a type'
                                    }}
                                    render={({ field }) => (
                                        <select {...field} className='form-control' onChange={(e) => setValue('type', e.target.value)}>
                                            <option value="" disabled>Select a type</option>
                                            <option value="dealer">Dealer</option>
                                            <option value="retailer">Retailer</option>
                                            <option value="distributor">Distributor</option>
                                            <option value="epc player">EPC Player</option>
                                            <option value="consumer">Consumer</option>
                                        </select>
                                    )}
                                />
                                {errors.type && <div className="error-message">{errors.type.message}</div>}
                            </div>

                            <div className="form-group" >
                                <textarea placeholder='Your message' className='form-control' {...register('message', { required: 'Required' })} />
                                {errors.message && <label className="error-message">{errors.message.message}</label>}
                            </div>

                            <input type="hidden" {...register('enquiry_type')} />

                            <div className="form-group">
                                <ReCAPTCHA
                                    sitekey="6Lc5zpQpAAAAAO9suPoYtJaeB5r57wNwKbkCbSjX"
                                    onChange={handleVerificationSuccess}
                                />
                                {captchaError && <div className="error-message">{captchaError}</div>}
                            </div>

                        </div>
                        <div className="form-footer">
                            <button className='common-btn enquiry-btn' type="submit">Submit</button>
                        </div>
                    </form>
                </div>
            </section>

        </>
    )
}

export default BrandDetail