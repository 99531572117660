import React from 'react'

const BigTitle2 = (props) => {
    return (
        <div className="big-title">
            <div className="big-title-content">
                <h1 className='big-title-heading'>{props.heading}</h1>
            </div>
        </div>
    )
}

export default BigTitle2