import React, { useState, useEffect } from 'react';
import "./BlogDetail.css"
import { useNavigate, useParams } from 'react-router-dom';
import AnchorLink from 'react-anchor-link-smooth-scroll'
import { ColorRing } from 'react-loader-spinner';
import { Helmet } from 'react-helmet-async';
import Breadcrumb from '../../components/BreadCrumb/BreadCrumb';
import AuthorSection from '../../components/AuthorSection/AuthorSection';
import NotFoundPage from '../../components/NotFoundPage/NotFoundPage';

const BlogDetail = () => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const Navigate = useNavigate()
    const { slug } = useParams()



    useEffect(() => {
        const fetchData = async () => {
            setError(null);
            try {
                const response = await fetch(`https://kesrinandan.com/admin/api/blog/details?slug=${slug}`)
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }

                const result = await response.json();
                if (!result?.data) {
                    throw new Error('Invalid slug');
                }
                setData(result.data);
            } catch (error) {
                setError(error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [slug]);

    if (error) {
        return <NotFoundPage />;
    }

    return (
        <>
            <Helmet>
                <title>{data?.meta_title}</title>
                <meta
                    name="description"
                    content={data?.meta_description}
                />
                
                <link rel="canonical" href={`https://kesrinandan.com/blog-detail/${slug}`} />

                {/* Facebook Meta Tags */}
                <meta property="og:url" content={`https://kesrinandan.com/blog-detail/${slug}`} />
                <meta property="og:type" content="website" />
                <meta property="og:title" content={data?.meta_title} />
                <meta property="og:description" content={data?.meta_description}/>
                <meta property="og:image" content="https://kesrinandan.com/static/media/blog-blogs-banner.cc759c79c5c3a06762df.png" />

                {/* Twitter Meta Tags */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta property="twitter:domain" content="kesrinandan.com" />
                <meta property="twitter:url" content={`https://kesrinandan.com/blog-detail/${slug}`} />
                <meta name="twitter:title" content={data?.meta_title} />
                <meta name="twitter:description" content={data?.meta_description} />
                <meta name="twitter:image" content="https://kesrinandan.com/static/media/blog-blogs-banner.cc759c79c5c3a06762df.png" />

            </Helmet>

            <section className="about-banner blogs-banner">
                <div className="custom-text custom-text-about">
                    <h1 className='custom-title'>Blog<span className='color-dot'>.</span></h1>
                    <Breadcrumb paths={[{ label: 'Home', url: '/' }, { label: 'Blogs', url: '/blogs' }]} />
                </div>
            </section>
            <div className="c-container">
                <section className="blog-detail-section">
                    {loading ? <ColorRing
                        visible={true}
                        height="80"
                        width="80"
                        ariaLabel="color-ring-loading"
                        wrapperStyle={{}}
                        wrapperClass="color-ring-wrapper"
                        colors={['#FFCA4D', '#FFCA4D', '#FFCA4D', '#FFCA4D', '#FFCA4D',]}
                    /> :
                        <>
                            <div className="blog-detail-container">
                                <img src={data?.image2} alt={data?.alt_tag2} />
                                <div className="blog-detail-header">
                                    <h2 className='blog-detail-title'>{data?.title}</h2>
                                    {/* <p className='blog-detail-created'>{data?.created}</p> */}
                                </div>
                                <p className='blog-detail-short-desc'></p>
                                <div>
                                    {loading ? <ColorRing
                                        visible={true}
                                        height="80"
                                        width="80"
                                        ariaLabel="color-ring-loading"
                                        wrapperStyle={{}}
                                        wrapperClass="color-ring-wrapper"
                                        colors={['#FFCA4D', '#FFCA4D', '#FFCA4D', '#FFCA4D', '#FFCA4D',]}
                                    /> :
                                        data?.description.map((el, index) => {
                                            return (
                                                <div className='blog-info' key={el?.id}>
                                                    <h2 id={`blog-info-${index + 1}`}>{`${el?.title}`}</h2>
                                                    <div dangerouslySetInnerHTML={{ __html: `${el?.description}` }} />
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                                <AuthorSection/>
                            </div>
                            <div className="blog-detail-context">
                                <h2>TABLE OF CONTEXT</h2>
                                {loading ? <ColorRing
                                    visible={true}
                                    height="80"
                                    width="80"
                                    ariaLabel="color-ring-loading"
                                    wrapperStyle={{}}
                                    wrapperClass="color-ring-wrapper"
                                    colors={['#FFCA4D', '#FFCA4D', '#FFCA4D', '#FFCA4D', '#FFCA4D',]}
                                /> :
                                    data?.description.map((el, index) => {
                                        return (
                                            <div key={el?.id}>
                                                <AnchorLink offset='100' className='blog-context' href={`#blog-info-${index + 1}`}>
                                                    <h3>{`${index + 1}. ${el?.title}`}</h3>
                                                </AnchorLink>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </>
                    }
                </section>
            </div>
            <div className="c-container">
                <section className="related-product-section">
                    <h2>More Blogs</h2>
                    <div className="related-products">
                        {
                            loading ? <ColorRing
                                visible={true}
                                height="80"
                                width="80"
                                ariaLabel="color-ring-loading"
                                wrapperStyle={{}}
                                wrapperClass="color-ring-wrapper"
                                colors={['#FFCA4D', '#FFCA4D', '#FFCA4D', '#FFCA4D', '#FFCA4D',]}
                            /> :
                                data?.more_blog?.map((el) => {
                                    return (
                                        <div key={el?.id} className='blog-section-card'>
                                            <img src={el?.image} alt={el?.alt_tag} />
                                            <p className='common-btn blog-btn'>{el?.created}</p>
                                            <h3 className='blog-card-title'>{el?.title}</h3>
                                            <p className='blog-card-desc'>{el?.short_description.length > 200 ? `${el?.short_description.substring(0, 200)} ...` : el?.short_description}</p>
                                            <p className='blog-card-btn' onClick={() => Navigate(`/blog-detail/${el?.slug}`)}>Read More</p>
                                        </div>
                                    )
                                })
                        }
                    </div>
                </section >
            </div>
        </>
    )
}

export default BlogDetail